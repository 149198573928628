import React from "react"
import Layout from '../components/layout'
import ProjectPreview from '../components/project-preview'
import { graphql, Link } from 'gatsby';
import styled from 'styled-components'
import Hero from '../components/hero'

const PaginationLinks = styled.div`
    margin: 1rem auto;
    max-width: 100%;
    width: 78ch;
    display: flex;
    justify-content: space-around;

    button{
        color: ${({ theme }) => theme.text};
        background: ${({ theme }) => theme.body};
        border: 0.5px solid ${({ theme }) => theme.text};
        border-radius: 3px;
        padding: 6px 16px;
        font-size: .7rem;
    }    
`

const ListOfProjects = ({ data, pageContext }) => {

  const projects = data.allContentfulProject.edges;
  const { currentPage, numPages } = pageContext;
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = currentPage - 1 === 1 ? '/' : (currentPage - 1).toString()
  const nextPage = (currentPage + 1).toString()
  return (
    <Layout>
      <Hero />
      {
        projects.map(({ node: project }) => {
          const title = project.title;
          const category = project.category;
          const slug = project.slug;
          const tags = project.tags;
          const description = project.shortDescription;
          const imageData = project.images[0].fluid;
          return (
            <div key={slug}>

              <ProjectPreview
                title={title} 
                slug={slug}
                description={description}
                imageData={imageData}
                tags={tags}
                category={category}
              />
            </div>
          )
        })
      }
      <PaginationLinks>
        {!isFirst && (
          <button>
            <Link to={prevPage} rel="prev">
              ← Previous Page
          </Link>
          </button>
        )}

        {!isLast && (
          <button>
            <Link to={nextPage} rel="next">
              More Projects →
          </Link>
          </button>
        )}
      </PaginationLinks>
    </Layout>

  );
}
export const query = graphql`
    query ($skip: Int!, $limit: Int!) {
     allContentfulProject(
        sort: {fields: createdAt, order: DESC}
        skip: $skip
        limit: $limit
     ) {
        edges {
          node {
            category
            slug
            tags
            title
            shortDescription
            images {
              fluid (maxWidth: 3080, quality: 100){
                ...GatsbyContentfulFluid
              }
            }
          }
        }
      }
    }
  `


export default ListOfProjects;